<template>
  <div>
    <head-layout  :head-title="'组件管理'" :head-btn-options="headBtnOptions"  @head-add="addFn()" >
    </head-layout>


        <div style="display: flex;;" class="search-form">
          <el-input placeholder="名称" v-model="queryList.name" class="new-sino-input" size="mini" style="width:200px" />

          <el-button-group style="margin-left: 10px;">
            <el-button size="mini" type="primary" icon="el-icon-search" @click="onLoad"></el-button>
          <el-button size="mini"  icon="reset-refresh icon-refresh" @click="czFn"></el-button>
          </el-button-group>

        </div>

        <grid-layout
            ref="gridLayout"
            :data-total="total"
            @page-size-change="handleSizeChange"
            @page-current-change="handleCurrentChange"
            @page-refresh-change="onLoad"
            :page="page"
            @gird-handle-select-click="selectionChange"
            :tableOptions="tableOptions"
            :tableData="tableData"
            :tableLoading="tableLoading"
            :gridRowBtn="gridRowBtn"
            @grid-edit="handleEdit"
            @grid-del="DelList"
          >
          <template slot="isSave" slot-scope="{row}" >
            {{row.isEnable=='0'?$t("cip.plat.sys.menu.field.no"):$t("cip.plat.sys.menu.field.yes")}}
            </template>
          </grid-layout>





  </div>
</template>

<script>
import {
  componentManageList,
  componentManageDel,
} from "@/api/dataAcquisition/index";
import { mapGetters } from "vuex";
import HeadLayout from "@/views/components/layout/head-layout"
import GridLayout from "@/views/components/layout/grid-layout";
import dialogHeadBtn from "@/views/components/layout/dialog-head-btn";
import {PAGE_CONSTANT} from "@/util/pageConstantEnum";
import formLayout from '@/views/components/layout/form-layout'
export default {
  components: {
    HeadLayout,
    GridLayout,
    dialogHeadBtn,
    formLayout
  },
  data() {
    return {
      page: {
        pageSize: 20,
        currentPage: 1,
        total: 0
      },
      tableLoading:false,
      gridRowBtn: [

        {
          label: this.$t('cip.cmn.btn.editBtn'),
          emit: "grid-edit",
          remark: 'edit',
          type: "text",
          icon: ""
        },
        {
          label: this.$t("cip.cmn.btn.delBtn"),
          emit: "grid-del",
          type: "text",
          icon: ""
        },
      ],
      tableOptions: {
        customRowAddBtn: false,
        menuWidth: 100,
        column: [
          {
            label: "名称",
            prop: "name",
            cell: true,
          },
          {
            label: "Code",
            prop: "code",
            cell: true,
          },
          {
            label: "类型",
            prop: "typeName",
            cell: true,
          },

          {
            label: "是否落库",
            prop: "isSave",
            slot:true,
            cell: true,

          },
        ],
      },
      multipleSelection:[],
      tableData: [],
      selDat:[],
      total:0,
      queryList:{
        query:{
          current:1,
          size:20,
        },
        name: "",
      },

      loading:false,

      headBtnOptions: [{
        label: this.$t(`cip.cmn.btn.addBtn`),
        emit: "head-add",
        type: "button",
        icon: ""
      }, ],
    };
  },
  computed: {
    ...mapGetters(["userInfo", "permission"]),
    permissionList() {
      return {
        addBtn: this.vaildData(this.permission.dept_add, false),
        viewBtn: this.vaildData(this.permission.dept_view, false),
        delBtn: this.vaildData(this.permission.dept_delete, false),
        editBtn: this.vaildData(this.permission.dept_edit, false)
      };
    },
  },
  methods: {
    onLoad(page, params = {}) {
      this.loading = true;
      componentManageList(this.queryList).then(res => {
        this.loading = false;
        let obj = res.data.data;
        this.tableData = obj.dataList
        this.total = obj.page.totalSize
        this.page.total=obj.total;
      });
    },
    addFn(){
      this.$router.push({
        path: '/Datadevelopment/componentManagementAdd',
        query: {
          type: 'add',
        }
      })
    },
    handleEdit(item){
      this.$router.push({
        path: '/Datadevelopment/componentManagementAdd',
        query: {
          type: 'edit',
          id:item.id
        },
      })
      localStorage.setItem('shuntEditObj',JSON.stringify(item))
    },
    DelList(row){
      console.log(row.id);

    this.$confirm(this.$t("cip.cmn.msg.warning.determineDelWarning"), this.$t("cip.dc.dataSubject.msg.Tips"), {
          confirmButtonText: this.$t("cip.cmn.btn.defBtn"),
          cancelButtonText: this.$t("cip.cmn.btn.celBtn"),
          type: 'warning'
        }).then(() => {
          componentManageDel({id:row.id}).then((res)=>{
            this.$message.success(this.$t("cip.cmn.msg.success.delSuccess"));
            this.onLoad()
          })
        }).catch(() => {

        });

    },
    czFn(){
      this.queryList = {
        query:{
          current:1,
          size:10,
        },
        name: "",
      }
      this.onLoad()
    },

    handleSizeChange(val) {
      this.queryList.query.current = 1
      this.queryList.query.size  = val.pageSize;
      this.page.currentPage = 1
      this.page.pageSize= val.pageSize
      this.onLoad()
    },
    handleCurrentChange(val) {
      this.page.currentPage = val.currentPage
      this.queryList.query.current = val.currentPage;
      this.onLoad()
    },

  },
  created() {
    this.onLoad()
  },
  mounted() {
  },
};
</script>
<style lang="less" scoped>
.search-form::v-deep{
   background: #FFFFFF;
   padding: 14px 12px;
  .el-form-item--small .el-form-item__content{
    line-height: 28px !important;
  }
  .el-button{
    height: 28px;
    margin-left:0;
   }
  .el-input__inner{
   height: 28px;
   line-height: 28px;
  }
}
::v-deep .el-button+.el-button {
  margin-left: 0px;
}
</style>
